.wrapper {
  align-items: center;
  background: #010101;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 10px 0px 44px 0px;
  position: relative;
  width: 100%;
}

.header {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 8px;
  position: relative;
  width: 100%;
  z-index: 10;
}

.title {
  align-items: center;
  border-radius: 100px;
  background: var(--app-primary-color);
  color: white;
  display: flex;
  grid-column: 2 / 4;
  justify-content: center;
  justify-self: center;
  padding: 8px 16px;
  text-align: center;
  width: fit-content;
}

.closeBtn {
  background-color: #171717;
  border: 1px solid rgba(255, 255, 255, 0.20);
  border-radius: 50%;
  cursor: pointer;
  height: 48px;
  justify-self: end;
  margin-right: 10px;
  position: relative;
  width: 48px;
}

.closeBtn svg {
  position: absolute;
  right: 13px;
  top: 13px;
}

.text {
  color: white;
  font-size: 36px;
  margin-bottom: 40px;
  text-align: center;
}

.startBtn {
  font-size: 16px;
  font-weight: 600;
  height: 67px;
  width: 100%;
}
