:root {
  --app-min-height: 500px;

  --app-primary-color: #007DFA;
  --app-secondary-color: #E0EFFE;
  --app-additional-color: #DDDEDF;
  --app-highlight-color: #FFF;

  --app-text-primary-color: #101012;
  --app-text-secondary-color: #424958;
  --app-text-additional-color: #BEBEBF;
  --app-text-highlight-color: #0078EF;

  --header-height: 72px;
  --footer-height: 170px;
}
