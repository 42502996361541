.exportBtn {
  align-items: center;
  display: flex;
}

.exportBtn {
  margin-right: 12px;
}

.exportBtn svg:first-of-type {
  margin-right: 8px;
}

.exportBtn svg:last-of-type {
  margin-left: 8px;
}

.exportBtn svg path {
  stroke: rgb(52, 64, 84);
}

.exportBtn svg g path,
.exportBtn svg path {
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.exportBtn:hover svg g path,
.exportBtn:hover svg path {
  stroke: #4096ff;
}
