.viewExamples {
  align-items: center;
  background: var(--Termination, #FFF);
  border: 1px solid var(--gray-300, #D0D5DD);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  color: var(--app-primary-color);
  display: flex;
  height: 44px;
  margin-left: 15px;
  padding: 10px 12px;
}

.viewExamples svg {
  margin-right: 6px;
}
