body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-collapse-extra{
  width: 24px;
  height: 24px;
}
.ant-collapse-extra svg{
  width: 24px;
  height: 24px;
}
.ant-collapse-extra svg path{
  width: 20px;
  height: 20px;
}
.ant-collapse{
  border: none;
}
.ant-collapse>.ant-collapse-item:last-child{
  border-radius: 0;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-header-text{
  color: #101828;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.ant-collapse .ant-collapse-content{
  border-top: none;
  background-color: #fff;
}
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header{
  background-color: #fff;
  padding: 25px 0 20px;
  align-items: center;
}
.ant-collapse .ant-collapse-content>.ant-collapse-content-box{
  padding: 0;
  background-color: #fff;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header{
  background-color: #fff;
  align-items: center;
  padding: 25px 0 20px;
}
.ant-collapse>.ant-collapse-item:last-child{
  border-bottom: none;
}
.ant-progress .ant-progress-text{
  display: none;
}
.ant-progress.ant-progress-status-success .ant-progress-bg{
  background-color: #1677ff;
}