.option {
  align-items: center;
  background: var(--White, #FFF);
  border-radius: 8px;
  border: 1px solid #EAECF0;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 16px;
  position: relative;
}

.option svg {
  margin-right: 10px;
}

.option.selected {
  background-color: #E0EFFE;
  color: var(--app-text-highlight-color);
}

.option.disabled:not(.selected) {
  background-color: #fafafa;
  cursor: default;
  color: var(--app-text-secondary-color);
}
