.wrapper {
  align-items: center;
  column-gap: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;
}

.titleWrapper {
  column-gap: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.field {
  align-items: center;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 90px 1fr;
  row-gap: 10px;
}

.label {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
}

.sessionsWrapper {
  column-gap: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 270px);
  margin-bottom: 20px;
  row-gap: 8px;
}

.seeMoreWrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.seeMore {
  color: var(--app-text-secondary-color);
  font-size: 12px;
  font-weight: 500;
  justify-self: end;
  margin-top: 20px;
}
