.wrapper h2 {
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
  margin-top: 16px;
  text-align: left;
}

.wrapper h3 {
  color: var(--app-text-secondary-color);
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  text-align: left;
}

.formBlock {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.inputWrapper,
.multiInputWrapper {
  max-width: 500px;
}

.inputWrapper {
  align-items: baseline;
  display: grid;
  grid-template-columns: minmax(100px, 500px) 50px;
}

.inputWrapper svg {
  margin-left: 10px;
}

.multiInputWrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.multiInputWrapper div:not(:first-child) {
  margin-left: 10px;
}

.label {
  font-size: 14px;
  font-weight: 500;
}

.actionButtons {
  align-items: baseline;
  margin-top: 24px;
}

.actionButtons button {
  width: 93px;
}
