.blockTitle {
  font-size: 18px;
  font-weight: 500;
}

.blockDescription {
  color: #667085;
  font-size: 14px;
  margin-bottom: 15px;
}

.formBlock {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.inputWrapper {
  align-items: baseline;
  display: flex;
}

.inputWrapper svg {
  margin-left: 10px;
}

.inputWrapper div:first-child {
  max-width: 500px;
}

.label {
  font-size: 14px;
  font-weight: 500;
}

.actionButtons {
  align-items: baseline;
  margin-top: 24px;
}

.actionButtons button {
  width: 93px;
}
