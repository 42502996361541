.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.requestsWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, 450px);
  column-gap: 20px;
  margin-bottom: 40px;
  row-gap: 20px;
}

.emptyList {
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
}

.listTools {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
}

.datePickerWrapper {
  align-items: center;
  display: flex;
}

.request {
  background: var(--White, #FFF);
  border: 1px solid #EAECF0;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  justify-content: space-between;
  padding: 16px 0px;
}

.cardHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
}

.cardData {
  color: var(--app-text-secondary-color);
  display: grid;
  column-gap: 10px;
  font-size: 12px;
  font-weight: 500;
  grid-template-columns: 1fr 0.7fr;
  padding: 0px 16px;
}

.requestNameWrapper,
.tools,
.deletePopover {
  align-items: center;
  display: flex;
}

.requestName {
  align-items: baseline;
  display: flex;
  flex-direction: column;
}

.date {
  color: var(--app-text-secondary-color);
  font-size: 14px;
}

.deletePopover svg path {
  stroke: #5A5A5D;
}

.deletePopover:hover svg path {
  stroke: var(--app-text-highlight-color);
}

.request svg {
  margin-right: 15px;
}

.showMore {
  justify-self: center;
  margin: 0 auto;
  margin-top: 30px;
  width: 130px;
}
