.wrapper h1 {
  margin-bottom: 20px;
}

.spinnerWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
}

.loadingMessageMain {
  color: 101828;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0px;
}

.loadingMessage {
  color: var(--app-text-secondary-color);
  font-size: 14px;
}

.dates {
  color: var(--app-text-secondary-color);
  font-size: 18px;
  margin-bottom: 20px;
}

.field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.label {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 6px;
}

.copyBtn {
  align-items: center;
  align-self: flex-end;
  display: flex;
  margin-top: 20px;
  width: fit-content;
}

.copyBtn svg g path {
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.copyBtn:hover svg g path {
  stroke: #4096ff;
}

.warning {
  align-items: center;
  background: #FFFAEB;
  border-radius: 16px;
  color: #B54708;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin: 4px 0px 10px 0px;
  padding: 4px 10px;
  width: fit-content;
}

.generateBtn:not(.disabled) {
  background-color: #E0EFFE;
  color: #007DFA;
}
