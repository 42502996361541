.userData {
  color: var(--app-text-secondary-color);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 0px 32px;
  position: relative;
}

.userMenuBtn {
  align-items: center;
  display: flex;
  position: relative;
}

.userMenuBtn svg path {
  stroke: black;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.userMenuBtn:hover svg path {
  stroke: #4096ff;
}

.userName {
  color: var(--app-text-primary-color);
  font-weight: 600;
}
