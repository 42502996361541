.topicName {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
  text-align: center;
}

.topicDescription {
  font-weight: 400;
  margin-bottom: 30px;
  text-align: center;
}

.subtopic {
  background: var(--White, #FFF);
  border: 1px solid var(--gray-200, #EAECF0);
  border-radius: 8px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 40px 1fr;
  margin-bottom: 12px;
  padding: 16px;
  position: relative;
}

.subtopic.active {
  background: #F4FAFF;
  border: 1px solid var(--Secondary, #E0EFFE);
  color: var(--app-text-highlight-color);
}

.subtopic.active .subtopicDescription {
  color: var(--app-text-highlight-color);
}

.subtopicName {
  font-weight: 500;
}

.subtopicName span {
  color: var(--app-primary-color);
  cursor: pointer;
  margin-left: 8px;
}

.subtopicName span:hover {
  text-decoration: underline;
}

.commentHints {
  margin-bottom: 30px;
  margin-top: -8px;
}

.hintList {
  display: flex;
}

.subtopicDescription {
  color: var(--app-text-secondary-color);
  font-size: 14px;
  font-weight: 400;
}

.radio {
  position: absolute;
  right: 0px;
  top: 15px;
}

.footer button {
  width: 100%;
}

.noSubtopics {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin-top: 24px;
  text-align: center;
}

.noSubtopics button {
  margin-top: 24px;
  width: 100px;
}
