.wrapper {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px 0;
  position: relative;
  width: 100%;
}

.header {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 8px;
  position: relative;
  width: 100%;
  z-index: 10;
}
.title {
  align-items: center;
  border-radius: 100px;
  background: var(--app-primary-color);
  color: white;
  display: flex;
  grid-column: 2 / 4;
  justify-content: center;
  justify-self: center;
  padding: 8px 16px;
  text-align: center;
  width: fit-content;
}
.balance {
  align-items: center;
  border-radius: 16px;
  background: #F2F4F7;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  grid-column: 2 / 4;
  justify-content: center;
  justify-self: center;
  padding: 2px 8px;
  width: fit-content;
}

.balance svg {
  margin-right: 6px;
}

.closeIcon {
  cursor: pointer;
  height: 20px;
  justify-self: end;
  margin-right: 10px;
  width: 20px;
}

.chatContent {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 24px 8px 24px;
}
.chatContent_noPadding{
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0;
  margin-top: 0;
}
.chatRobotImg {
  height: 100%;
  width: 100%;
}

.newMessageWrapper {
  align-items: center;
  border-top: 1px solid #EAECF0;
  column-gap: 12px;
  display: grid;
  grid-template-columns: 1fr 44px;
  margin-top: auto;
  padding: 16px 24px;
}

.newMessageWrapper button {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 44px;
}

.controlBtnWrapper {
  border-top: 1px solid #EAECF0;
  column-gap: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 24px;
  padding-top: 16px;
}

.stopChatBtn {
  width: 100%;
}

.newChatBtn {
  background-color: var(--app-secondary-color);
  border: var(--app-secondary-color);
  color: var(--app-primary-color);
  height: 40px;
  /* margin: 0px 24px; */
  width: 100%;
}
.chatTextDescr {
  padding: 4px 4px 4px 4px;
  background: #007DFA;
  margin-top: 50px;
  margin-bottom: 25px;
}
.chatTextDescr p {
  color: #FFF;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0;
  width: 100%;
}
.divider{
  height: 1px;
  background-color: #EAECF0;
  width: 100%;
}
.promptButton{
  color: #015F7F;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 16px;
  background: #CAECF7;
  width: 67px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.promptButton__selected{
  border-radius: 16px;
  border: 1px solid #015F7F;
  background: #CAECF7;
  box-shadow: 0 0 6px 0 rgba(1, 95, 127, 0.25);
}
.promptButtons{
  padding: 0 24px;
}
.promptButtons__inner{
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.promptButtons__prompts{
  display: flex;
  gap: 6px;
}
.promptButtons__skip{
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: #344054;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  height: 44px;
}
