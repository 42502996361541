.wrapper h1 {
  margin-bottom: 10px;
  margin-top: 34px;
}

.pageTitle {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
