.inner {
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #EAECF0);
  background: var(--Termination, #FFF);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.10);
  padding: 12px 24px 14px;
  width: 100%;
}

.title {
  align-items: center;
  color: var(--Gray-900, var(--Text-Primary, #101828));
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin: 0 0 24px;

  svg {
    margin-right: 8px;
  }
}

.cardValue {
  color: var(--Gray-900, var(--Text-Primary, #101828));
  font-family: Inter, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
  margin: 0;
}
