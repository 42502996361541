.wrapper h1 {
  margin-bottom: 10px;
  margin-top: 34px;
}

.pageTitle {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.formWrapper h2 {
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
  margin-top: 16px;
  text-align: left;
}

.formWrapper h3 {
  color: var(--app-text-secondary-color);
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  margin-top: 10px;
  text-align: left;
}

.formBlock {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.inputWrapper {
  align-items: center;
  display: flex;
  max-width: 500px;
  margin-bottom: 25px;
  position: relative;
}

.deleteIcon {
  cursor: pointer;
  position: absolute;
  right: -30px;
}

.deleteIcon:hover path {
  stroke: var(--app-text-highlight-color);
}

.label {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.topicList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 450px);
  column-gap: 20px;
  margin-bottom: 40px;
  row-gap: 20px;
}

.addNewBtn {
  background: var(--Secondary, #E0EFFE);
  border: 1px solid var(--Secondary, #E0EFFE);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--app-primary-color);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin: 0 auto;
  padding: 10px 16px;
  width: fit-content;
}

.validationMessage {
  color: var(--app-text-secondary-color);
  font-size: 12px;
  margin-right: 15px;
}

.actionButtons {
  align-items: baseline;
}
