.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin: 20px 0px 8px 0px;
}

.actionButtons {
  align-items: baseline;
}

.actionButtons button {
  margin-top: 32px;
  width: 100%;
}
