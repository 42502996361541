.tip {
  border-bottom: 1px solid #EAECF0;
  margin-bottom: 20px;

  h3 {
    color: var(--Gray-900, var(--Text-Primary, #101828));
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin: 0 0 5px;
    text-align: left;
  }

  &__name {
    background-color: white;
    margin: 0 auto;
    max-width: 83.5%;
  }

  &__image{
    max-width: 346px;
    width: 100%;
    height: 450px;
    margin: 0 auto;
    display: block;
    object-fit: contain;
  }

  &__text{
    color: #667085;
    font-size: 16px;
    font-weight: 400;
  }

  ol {
    list-style-type: none;
    padding-left: 0px;

    li {
      margin-bottom: 4px;
    }

    li.bulletList {
      align-items: center;
      column-gap: 8px;
      display: grid;
      font-size: 16px;
      grid-template-columns: 20px 1fr;

      span {
        font-weight: 500;
      }
    }
  }
}
