.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.listTools {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
}

.inputsWrapper,
.datePickerWrapper,
.toolButtonsWrapper {
  align-items: center;
  display: flex;
}

.datePickerWrapper {
  margin-left: 20px;
}

.tableActions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tableActions svg {
  cursor: pointer;
}

.tableActions svg:hover path {
  stroke: var(--app-text-highlight-color);
}

.tableActions svg.addSessionIcon:hover path:first-of-type {
  stroke: transparent;
}
