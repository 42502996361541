.title{
  color: var(--Gray-900, #101828);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  margin: 20px 0;
}
.formItem{
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: 100%;
  label{
    color: var(--Gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin-bottom: 6px;
  }
}