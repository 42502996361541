.titleWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.status {
  background-color: #FFFAEB;
  border-radius: 16px;
  color: #B54708;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
}

.status.active {
  background-color: #ECFDF3;
  color: #027A48;
}

.tableActions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.tableActions svg {
  cursor: pointer;
}

.tableActions svg:not(:first-of-type),
.tableActions a {
  margin-left: 10px;
}

.tableActions svg:hover path {
  stroke: var(--app-text-highlight-color);
}

.tableActions svg.addSessionIcon:hover path:first-of-type {
  stroke: transparent;
}

.exportSessionsWrapper {
  align-items: center;
  color: var(--app-text-secondary-color);
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  margin-top: 15px;
}

.exportSessionsWrapper button {
  align-items: center;
  display: flex;
  height: 44px;
  margin-left: 10px;
}

.exportSessionsWrapper button svg {
  margin-right: 6px;
}
