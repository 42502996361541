.wrapper {
  column-gap: 25px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.blockWrapper {
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  padding: 24px;
}

.reportTypeOptions {
  display: flex;
  flex-direction: column;
}

.reportTypeOption {
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px;
  position: relative;
  width: 100%;
}

.reportTypeOption.active {
  border: 2px solid var(--app-primary-color);
}

.reportTypeOption.disabled {
  background-color: #fafafa;
  cursor: default;
  color: var(--app-text-secondary-color);
}

.reportTypeOption.unavailable {
  border: 1px solid #B54708;
  border-radius: 8px;
}

.reportTypeOption.reportTypeOption.active.unavailable {
  border: 2px solid var(--app-primary-color);
  outline: 1px solid #B54708;
  outline-offset: -3px;
}

.unavailableIcon {
  position: absolute;
  top: -10px;
  right: -10px;
}

.unavailableIcon rect {
  display: none;
}

.reportTypeData {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.actionButtons {
  margin-top: 20px;
}

.generateBtn.repeatGeneration:not(.disabled) {
  background-color: #E0EFFE;
  color: #007DFA;
}

.warning {
  color: var(--app-text-secondary-color);
  font-size: 12px;
  margin-right: 15px;
}
