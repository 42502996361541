.saveSessionModal {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.modalTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
}

.actionButtons {
  align-items: baseline;
}

.actionButtons button,
.actionButtons div {
  margin-top: 24px;
  width: 100%;
}

.confirmWarning {
  color: var(--app-text-secondary-color);
  font-size: 12px;
  margin-top: 0px !important;
  text-align: center;
}
