.wrapper {
  margin: 40px 0px 32px 0px;

  .transactionTitle {
    font-size: 14px;
    font-weight: 500;
  }

  .transactionDescription {
    color: var(--app-text-secondary-color);
    font-size: 14px;
    font-weight: 400;
  }
}

.customHeader {
  // padding: 10px 16px;

  &__toolbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}
