.badge{
  border-radius: 6px;
  background: #EDE9FE;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  padding: 3px 10px 3px 10px;
  &__dot{
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #007DFA;
  }
  span{
    color: #007DFA;
    text-align: center;
    font-family: "IBM Plex Mono", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.72px;
    text-transform: capitalize;
    margin-left: 8px;
  }
}