.additionalGenerateBtns {
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
}

.generateAgain {
  background-color: #E0EFFE;
  color: #007DFA;
}

.actionButtonsWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.copyBtn {
  align-items: center;
  display: flex;
}

.copyBtn svg g path {
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.copyBtn:hover svg g path {
  stroke: #4096ff;
}

.copyHintContent {
  color: var(--app-text-secondary-color);
}

.copyHintContent span {
  font-weight: 500;
}

.reportResult p{
  margin: 0 0 10px 0;
}

.reportResult h4{
  margin: 10px 0;
}

.reportResult li{
  white-space: initial;
}

.reportResult ol{
  margin: 0;
}

.reportResult div{
  white-space: initial !important;
}