.reportTitle {
  font-weight: 500;
  margin-top: 10px;
}

.reportContent {
  white-space: pre-wrap;

}

.reportContent h3 {
  font-size: 22px;
  margin: 10px 0px;
  text-align: left;
}

.summaryTitle {
  font-size: 30px;
  font-weight: 600;
  margin: 35px 0px 12px 0px;
}

.reportContent p{
  margin: 0 0 10px 0;
}

.reportContent h4{
  margin: 10px 0;
}

.reportContent li{
  white-space: initial;
}

.reportContent ol{
  margin: 0;
}

.reportContent div{
  white-space: initial !important;
}
