.wrapper h1 {
  margin-bottom: 10px;
  margin-top: 34px;
  font-size: 40px;
}

.wrapper h2 {
  font-size: 32px;
}

.wrapper h3 {
  font-size: 24px;
}

.wrapper h4 {
  font-size: 18px;
}


.pageTitle {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.tabLabel {
  align-items: center;
  display: flex;
}

.tabLabel svg {
  margin-left: 8px;
}

.tools {
  align-items: center;
  display: flex;
}

.generateReport {
  align-items: center;
  background: #000;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: white;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  margin-left: 10px;
  padding: 10px 16px;
}

.generateReport:hover {
  background: #949494;
}

.generateReport svg {
  margin-right: 12px;
}
