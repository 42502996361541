.instancesInformation {
  column-gap: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  margin-bottom: 20px;
  row-gap: 8px;
}

.noOptions {
  align-items: center;
  background: #FFFAEB;
  border-radius: 16px;
  color: #B54708;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  grid-column: 1/4;
  padding: 4px 10px;
  width: fit-content;
}

.noOptions svg {
  margin-right: 10px;
}
