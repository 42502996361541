.wrapper {
  align-self: flex-end;
  margin-bottom: 16px;
  max-width: 100%;
  width: fit-content;
}

.wrapper.incoming {
  align-self: flex-start;
  column-gap: 12px;
  display: grid;
  grid-template-columns: 30px 1fr;
}

.messageContent {
  display: flex;
  flex-direction: column;
}

.authorWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}

.author {
  font-size: 14px;
  font-weight: 500;
}

.date {
  color: var(--app-text-secondary-color);
  font-size: 12px;
  font-weight: 400;
  margin-left: 7px;
}

.message {
  /* align-items: center; */
  background: var(--app-primary-color);
  border-radius: 8px 0 8px 8px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 28px;
  padding: 10px 14px;
}
.message p {
  margin: 0;
}
.message.incoming h3{
  text-align: start;
  font-size: 22px;
  font-weight: 600;
}
.message.incoming p{
  margin: 0 0 10px 0;
}

.message.incoming ol, ul{
  padding-left: 30px;
}

.message.incoming ol ul{
  padding-left: 20px;
}

.message.incoming li + li{
  margin-top: 10px;
}

.message.incoming {
  background: #F2F4F7;
  border-radius: 0 8px 8px 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.10);
  color: var(--app-text-primary-color);
}

.message.warning {
  color: #DC6803;
}

.typing {
  position: relative;
}

.typing span {
  content: '';
  animation: blink 1.5s infinite;
  animation-fill-mode: both;
  height: 4px;
  width: 4px;
  background: #667085;
  position: absolute;
  left:0;
  top:0;
  border-radius: 50%;
}

.typing span:nth-child(2) {
  animation-delay: .2s;
  margin-left: calc(4px * 1.5);
}

.typing span:nth-child(3) {
  animation-delay: .2s;
  margin-left: calc(8px * 1.5);
}

@keyframes blink {
  0% {
    opacity: .1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .1;
  }
}
