.wrapper {
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.paginationWrapper {
  width: 100%;
}

.paginationWrapper > div {
  display: grid;
  grid-template-columns: 1px 100px 1fr 101px;
  width: 100%;
}

.pageNumbers {
  justify-self: center;
}

.emptyTable {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
