.selectTopic {
  color: var(--app-text-highlight-color);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.tableHeader {
  padding: 20px 24px 19px 24px;
}

.headerTitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}

.headerDescription {
  color: var(--app-text-secondary-color);
  font-size: 14px;
  font-weight: 400;
}

.topicColumn {
  font-weight: 600;
  cursor: pointer;
}

.subtopicName div:not(:last-of-type) {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
}

.subtopicComment {
  display: flex;
  flex-direction: column;
  overflow-x: auto
}

.subtopicComment div:not(:last-of-type) {
  margin-bottom: 6px;
}

.subtopicComment::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.subtopicComment {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.formTitle{
  color:#101828;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 20px 0;
}
.formLabel{
  color: #344054;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 6px 0;
  display: block;
}
.formSelectDesc{
  color: #424958;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 4px 0 32px;
}
.actionButtons{
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.addInstanceBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #344054;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border: none;
  border-right: 1px solid #D0D5DD;
  background: #FFF;
  outline: none;
  padding: 10px 16px;
  cursor: pointer;
}

.addInstanceBtn span {
  margin-left: 8px;
}
