.wrapper h2 {
  font-size: 18px;
  font-weight: 500;
  margin: 0px;
  margin-top: 16px;
  text-align: left;
}

.wrapper h3 {
  color: var(--app-text-secondary-color);
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  text-align: left;
}

.formBlock {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.inputWrapper {
  align-items: baseline;
  display: grid;
  grid-template-columns: minmax(100px, 500px) 50px;
}

.inputWrapper svg {
  margin-left: 10px;
}

.label {
  font-size: 14px;
  font-weight: 500;
}

.tokensWrapper {
  column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.tokenData {
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #EAECF0);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  padding: 12px 24px;
}

.tokenLabel {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
}

.tokenCount {
  font-size: 36px;
  font-weight: 600;
}

.actionButtons {
  align-items: baseline;
  margin-top: 24px;
}

.actionButtons button {
  width: 93px;
}
