.inner{
  position: fixed;
  background: rgba(0, 0, 0, 0.40);
  top: 0;
  width: 100vw;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 32px 0;
  height: 100vh;
  &__content{
    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    width: 430px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    display: flex;
    height: 268px;
  }
  &__btn{
    width: 100%;
  }
}
.title{
  color: var(--Gray-900, #101828);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  margin: 20px 0 8px 0;
}
.text{
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin:  0 0 32px 0;
}