.wrapper {
  background: var(--White, #FFF);
  border: 1px solid #EAECF0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  justify-content: space-between;
  padding: 16px 0px;
}

.mainInfo {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
}

.tools svg path {
  stroke: #5A5A5D;
}

.tools svg:hover path {
  stroke: var(--app-text-highlight-color);
}

.wrapper svg {
  margin-right: 15px;
}

.nameWrapper,
.tools,
.deletePopover {
  align-items: center;
  display: flex;
}

.name {
  align-items: baseline;
  display: flex;
  flex-direction: column;
}

.description {
  color: var(--app-text-secondary-color);
  column-gap: 10px;
  font-size: 12px;
  font-weight: 500;
  padding: 0px 16px;
}
