@import './styleVars.css';

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

input:-webkit-autofill::first-line {
  font-size: 16px !important;
  font-family: 'Inter', sans-serif;
}

h1 {
  font-size: 70px;
  font-weight: 800;
  margin: 0px;
  text-align: center;
}

h2 {
  font-size: 68px;
  font-weight: 800;
  margin: 0px;
  text-align: center;
}

h3 {
  color: black;
  font-size: 32px;
  font-weight: 400;
  margin: 0px;
  text-align: center;
}

.app {
  height: 100vh;
  min-height: var(--app-min-height);
}

.appContent {
  padding: 0px 80px 20px 80px;
}

.pageTitle {
  color: var(--app-text-primary-color);
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
  margin: 0px;
  margin-bottom: 4px;
  text-align: left;
}

.pageDescription {
  color: var(--app-text-secondary-color);
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 24px;
  text-align: left;
}

.tableSecondaryTextStyle {
  color: var(--app-text-secondary-color);
}

.actionButtons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.actionButtons button:not(:last-child),
.actionButtons a:not(:last-child) {
  margin-right: 12px;
}


.deleteBtn {
  background: #D92D20;
  border: 1px solid #D92D20;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: white;
}

.deleteBtn:hover {
  background: #D92D20 !important;
  border-color: #a22016 !important;
  color: white !important;
}

.MuiDataGrid-columnHeaders {
  background-color: #F9FAFB;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  color: var(--app-text-secondary-color);
  font-size: 12px;
}

.customToolbarTable .MuiDataGrid-columnHeaders {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.emptyTableWrapper .MuiDataGrid-virtualScroller {
  height: 200px !important;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  h1 {
    font-size: 44px;
  }
  
  h2 {
    font-size: 40px;
  }
  
  h3 {
    font-size: 26px;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
  
  h2 {
    font-size: 32px;
  }
  
  h3 {
    font-size: 20px;
  }

  .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 100px) and (max-width: 479px) {
  h1 {
    font-size: 24px;
  }
  
  h2 {
    font-size: 24px;
  }
  
  h3 {
    font-size: 16px;
  }

  .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }

  .ant-input-number-group-addon {
    padding: 0px 2px !important;
  }
}
