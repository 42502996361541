.titleWrapper,
.tableActions {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.userNameColumn {
  color: #101828;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
}

.nickname {
  color: var(--app-text-secondary-color);
  font-size: 14px;
  font-weight: 400;
}

.status {
  background-color: #FFFAEB;
  border-radius: 16px;
  color: #B54708;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
}

.status.active {
  background-color: #ECFDF3;
  color: #027A48;
}

.tableActions {
  width: 100%;
}

.tableActions svg {
  cursor: pointer;
}

.tableActions svg:hover path {
  stroke: var(--app-text-highlight-color);
}
.columnBadge{
  border-radius: 16px;
  background: #ECFDF3;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  padding: 2px 8px;
  line-height: 18px; /* 150% */
}
.cancelBtn {
  color: #667085;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.confirmBtn{
  color: #007DFA;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
