.mainInfo {
  display: grid;
  grid-template-columns: 1fr 120px 1fr;

  .mainInfoBlock {

    .blockTitle {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      button {
        display: flex;
        font-size: 14px;
        justify-content: center;
        width: 93px;
      }
    }
  }
}
