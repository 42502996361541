.buttons{
  display: flex;
  justify-content: flex-end;
  padding: 20px 144px;
  gap: 20px;
}
.container{
  padding: 0 240px;
}
.header{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  img{
    width: 142px;
    height: 125px;
  }
  h1{
    color: var(--Gray-900, var(--Text-Primary, #101828));
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.96px;
    margin-top: 24px;
  }
  h2{
    color: var(--Text-Primary, #101828);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-top: 24px;
    margin-bottom: 40px;
  }
}
.commonTitle{
  color: var(--Gray-900, var(--Text-Primary, #101828));
  font-family: Inter, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  text-align: start;
  margin-bottom: 24px;
}

.commonText{
  color: var(--Text-xs, #424958);
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.email{
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 96px;
  span{
    &:first-child{
      color: var(--Text-xs, #424958);
      font-family: Inter, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
    a{
      color: var(--Primary, #007DFA);
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      text-decoration-line: underline;
      display: block;
    }
  }
}
h4{
  color: var(--Gray-900, var(--Text-Primary, #101828));
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;
}

@media (max-width: 1220px) {
  .container{
    padding: 0 120px;
  }
  .buttons{
    padding: 20px 60px;
  }
}
@media (max-width: 900px) {
  .container{
    padding: 0 60px;
  }
}

@media (max-width: 600px) {
  .buttons{
    padding: 20px;
  }
  .container{
    padding: 0 20px;
  }
}
@media (max-width: 480px) {
  .buttons{
    gap: 5px;
    justify-content: center;
  }
}