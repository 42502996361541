.wrapper {
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  display: flex;
  margin-bottom: 20px;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.option {
  border-right: 1px solid #D0D5DD;
  color: #3B414B;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  min-width: fit-content;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper div:first-child  {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.option.active {
  background-color: #F9FAFB;
  color: var(--app-text-primary-color);
}
