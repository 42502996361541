.chatIcon {
  animation: pulse 2s infinite;
  border-radius: 50%;
  bottom: 20px;
  box-shadow: 0 0 0 0 rgba(209, 233, 255, 0.60);
  cursor: pointer;
  height: 66px;
  position: fixed;
  right: 10px;
  transform: scale(1);
  width: 66px;
}

.chatWindow {
  bottom: 20px;
  position: fixed !important;
  right: 20px;
  width: 25vw;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(169, 203, 235, 0.6);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}
