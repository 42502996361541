.wrapper {
  margin-bottom: 40px;
}

.sessionTitle {
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  margin-top: 10px;
}

.notesLabel {
  color: var(--app-text-secondary-color);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 15px;
}

.sessionNote {
  background: var(--gray-50, #F9FAFB);
  border: 1px solid var(--gray-300, #D0D5DD);
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  min-height: 60px;
  padding: 10px 14px;
}

.instanceName {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 32px;
}

.instanceItem {
  margin-left: 20px;
}

.topicName {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 20px;
  text-decoration-line: underline;
}

.subtopic {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 150px 1fr;
  margin-top: 10px;
}

.subtopicName {
  border-radius: 16px;
  color: #3B414B;
  font-size: 12px;
  font-weight: 500;
  height: fit-content;
  margin-right: 10px;
  padding: 2px 10px;
  text-align: center;
  width: 100%;
}

.subtopicInfo {
  color: var(--app-text-secondary-color);
  font-size: 18px;
  font-weight: 400;
}

.comments {
  color: var(--app-text-highlight-color);
  font-size: 18px;
  font-weight: 500;
  margin-top: 5px;
}

.comments span {
  font-weight: 400;
}
