.header {
  padding: 0 104px 0 80px;
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__buttons {
    display: flex;
    gap: 20px;
  }
}

.commonText {
  color: #374151;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.2px;
  margin: 0;
}

.commonTitle {
  color: #000;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 57.2px;
  letter-spacing: -1.5px;

  &_grey {
    color: #9CA3AF;
  }

  &_blue {
    color: #0078EF;
  }
}

.logo {
  height: 100%;
  padding: 8px 0 0 0;
}

.loginBtn {
  border-radius: 10px;
  background: linear-gradient(180deg, #FDFDFD 0%, #F6F6F7 100%);
  box-shadow: 0 2px 6px 0 rgba(17, 3, 48, 0.07), 0 1px 1px 0 rgba(0, 0, 0, 0.03);
  color: #030712;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.25px;
  letter-spacing: -0.3px;
  padding: 11px 52px;
  border: 1px solid #E5E7EB;
  transition: 0.5s;

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(17, 3, 48, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  }
}

.earlyAccessBtn {
  border-radius: 10px;
  background: var(--Primary, #007DFA);
  box-shadow: 0 4px 6px 0 rgba(30, 7, 81, 0.12);
  color: var(--Termination, #FFF);
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.25px;
  letter-spacing: -0.3px;
  padding: 11px 27.5px;
  border: none;
  transition: 0.5s;

  &:hover {
    background-color: #0168ce;
  }
}




.transform {
  padding: 60px 60px 100px 80px;

  &__title {
    max-width: 75%;
  }

  &__text {
    margin-top: 10px;
    margin-bottom: 75px;
    max-width: 45%;
  }

  &__content {
    display: flex;
  }

  &__contentBox {
    margin-left: 40px;
    text-align: start;
  }

  &__contentBoxTitle {
    text-align: start;
    margin-top: 25px;
    margin-bottom: 12px;
  }

  &__img {
    max-width: 639px;
  }

}

.block {
  &_greyBg {
    background: #F4F6FB;
  }

  &__whiteBg {
    background-color: #FFFFFF;
  }

  h1 {
    color: #0078EF;
    font-family: Inter, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 73.5px;
    letter-spacing: -2px;
    text-align: start;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
.landingHero {
  display: flex;
  padding: 60px 0 60px 80px;

  &__inner {
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

  }
  &__title{
    font-size: 72px !important;
    max-width: 95% !important;
  }

  &__img {
    max-width: 692px;
  }
  &__text{
    font-size: 24px !important;
    line-height: 140% !important;
  }
}

.mainBlock__buttons {
  margin-top: 40px;
  display: flex;
  gap: 15px;
}


button {
  cursor: pointer;
}

.unlock {
  padding: 80px 20px 110px 80px;
  display: flex;

  &__content {
    margin-right: 40px;
    margin-top: 47px;
  }

  &__contentTitle {
    text-align: start;
    margin-top: 12px;
    margin-bottom: 9px;
  }

  &__imgInner {
    border-radius: 26px;
  }

  &__earlyAccess {
    margin-top: 40px;
  }

  &__img {
    max-width: 724px;
  }
}


//Beyond expectations block
.beyond {
  padding: 60px 210px 134px 210px;

  &__textContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__blocks {
    display: flex;
    gap: 20px;
    margin-top: 50px;
  }

  &__block {
    border-radius: 20px;
    border: 1px solid rgba(27, 27, 27, 0.08);
    box-shadow: 0 7px 24px 0 rgba(27, 27, 27, 0.25);
    padding: 40px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    h4 {
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px; /* 230.769% */
      letter-spacing: -1.04px;
      margin: 15px 0;
    }

    p {
      color: #010D3E;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: -0.16px;
      margin: 0;
    }
  }
}

.revolution {
  background-color: #F9FAFB;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 24px;
  padding-top: 60px;
  text-align: center;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  &__title {
    color: #111827;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: 73.5px;
    letter-spacing: -2px;
    padding: 0 200px;
    margin-bottom: 10px;
    margin-top: 0;
  }

  &__buttons {
    margin-top: 37px;
    display: flex;
    gap: 20px;
    margin-bottom: 37px;
  }
}

.joinRevolution {
  padding: 96px 80px;
}

@media (max-width: 1600px) {
  .transform {
    &__title {
      max-width: 844px;
      width: 100%;
    }
  }
}
@media (max-width: 1440px) {
  .landingHero {
    &__title{
      font-size: 64px !important;
      max-width: 100% !important;
    }
    &__text{
      font-size: 18px !important;
      line-height: 140% !important;
    }
  }
}
@media (max-width: 1220px) {
  .block {
    h1 {
      text-align: center;
    }

  }
  .landingHero {
    flex-direction: column;
    align-items: center;
    padding: 60px 20px 60px 20px;
    text-align: center;

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-right: 0;
    }

    &__img {
      border-radius: 24px;
      width: 100%;
      max-width: 100%;
    }
  }
  .mainBlock {
    &__buttons {
      margin-bottom: 40px;
    }
  }
  .transform {
    padding: 60px 60px 60px 80px;

    &__title {
      max-width: 100%;
    }

    &__text {
      max-width: 100%;
    }

    &__content {
      flex-direction: column-reverse;
      align-items: center;

      img {
        width: 100%;
        max-width: 100%;
        margin-top: 40px;
      }
    }

    &__contentBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: start;
      margin-left: 0;
    }
  }
  .unlock {
    padding: 60px 60px 60px 80px;
    flex-direction: column;
    align-items: center;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-top: 0;
      margin-right: 0;
    }

    &__contentTitle {
      text-align: center;
    }

    &__imgInner {
      margin-top: 40px;

      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .beyond {
    padding: 60px 60px 60px 80px;
  }
}

@media (max-width: 1024px) {
  .header {
    padding: 0 40px;
  }
  .beyond {
    &__blocks {
      flex-direction: column;
    }
  }
}


@media (max-width: 820px) {
  .revolution {
    &__title {
      padding: 0 20px;
      font-size: 52px;
    }
  }
  .landingHero {
    padding: 40px 20px;
  }
  .transform {
    padding: 40px 20px;
  }
  .unlock {
    padding: 40px 20px;
  }
  .beyond {
    padding: 40px 20px;
  }
  .joinRevolution {
    padding: 40px 20px;
  }
  .mobile {
    &__menu {
      position: absolute;
      z-index: 10;
      width: 100vw;
      background: #FFFFFF;
      border: 1px solid #F2F4F7;
      box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      right: 0;
      top: 77px;
    }

    &__buttons {
      display: flex;
      flex-direction: column-reverse;
      gap: 12px;
      padding: 24px 16px;
    }
  }
  .loginBtn {
    &_blue {
      color: #007DFA;
    }
  }
}

@media (max-width: 650px) {
  .header {
    height: 78px;
    padding: 0 20px;
  }
  .block {
    h1 {
      color: var(--Primary, #007DFA);
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 45px;
      font-style: normal;
      font-weight: 600;
      line-height: 50px;
      letter-spacing: -2px;
      margin: 15px 0;
      max-width: 333px;
      width: 100%;
    }
  }
  .landingHero {
    &__title{
      font-size: 36px !important;
      max-width: 100% !important;
    }
    &__text{
      font-size: 16px !important;
      line-height: 140% !important;
    }
  }
  .commonText {
    color: #374151;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.2px;
  }
  .commonTitle {
    color: #000;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: -1px;
  }
  .mainBlock {
    &__buttons {
      margin-top: 23px;
      margin-bottom: 40px;
      flex-direction: column;
      width: 100%;
      gap: 23px;

      button {
        width: 100%;
      }
    }
  }
  .transform {
    padding: 60px 20px 40px;

    &__contentBox {
      text-align: start;

      p {
        text-align: start;
        margin-bottom: 40px;
      }
    }

    &__text {
      margin-top: 5px;
      margin-bottom: 55px;
    }

    &__contentBoxTitle {
      text-align: start;
      margin-top: 12px;
    }

    &__content {
      img {
        border-radius: 24px;
        background: #F5F5F5;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);

      }
    }

    &__title {
      max-width: 320px;
    }
  }
  .unlock {
    padding: 40px 20px;

    &__content {
      align-items: flex-start;
      text-align: start;

      p {
        text-align: start;
      }
    }

    &__contentTitle {
      text-align: start;
    }

    &__earlyAccess {
      margin-top: 12px;
      width: 100%;
    }

    &__earlyAccessButton {
      width: 100%;
    }
  }
  .beyond {
    &__blocks {
      gap: 16px;
      margin-top: 40px;
    }

    &__block {
      padding: 30px 8px;

      h4 {
        line-height: initial;
      }
    }
  }
  .joinRevolution {
    padding: 0;
  }
  .revolution {
    padding: 90px 20px 120px;

    &__title {
      color: var(--Primary, #111827);
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 44px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: -2px;
      margin-bottom: 27px;
    }

    &__buttons {
      margin-top: 24px;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 24px;
      width: 100%;
    }

    &__img {
      border-radius: 10px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
    }
  }

}

@media (max-width: 480px) {
  .transform {
    &__title {
      max-width: 320px;
    }
  }
}
