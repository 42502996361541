.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  min-height: var(--app-min-height);
}

.form {
  align-self: center;
  justify-self: center;
  min-width: 360px;
  max-width: 500px;
  width: 50%;
}

.form h1 {
  color: var(--app-text-primary-color);
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 12px;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.form button {
  width: 100%;
} 

.signInImage {
  height: calc(100vh);
  /* min-height: var(--app-min-height); */
  padding: 40px 0 40px 0;
  overflow: hidden;
  width: 100%;
}

.signInImage img {
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}


.signUpImage{
  background: linear-gradient(155deg, rgba(243, 245, 248, 0.20) 2.07%, rgba(0, 125, 250, 0.20) 124.53%);
  display: flex;
  flex-direction: column;
}

.signUpImage img{
  height: 550px;
  max-width: 635px;
  width: 100%;
  align-self: flex-end;
}

.signUpImage p {
  color: #0078EF;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  max-width: 439px;
  width: 100%;
  margin: 80px 0 120px 80px;
}
.logo{
  position: absolute;
  top: 16px;
  left: 32px;
  width: 73px;
  height: 64px;
}
.signUpTitle{
  text-align: start !important;
  margin-bottom: 12px;
}
.signUpText{
  color: #667085;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 32px 0;
}
.label{
  color:#344054;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 6px;
}
.checkboxInner{
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;
}

.checkboxInner a{
  color: #344054;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
}

.checkboxInner p{
  margin: 0;
}

.haveAcc{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.haveAcc p{
  margin: 0;
}

.haveAcc span{
  color: #007DFA;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}
.haveAcc + .haveAcc{
  margin-top: 15px;
}
@media (max-width: 1280px) {
  .signUpImage img{
    object-fit: contain;
    max-width: 550px;
  }
}
@media (max-width: 1280px) {
  .signUpImage img{
    object-fit: contain;
    max-width: 450px;
  }
}
@media (max-width: 1040px) {
  .signUpImage p{
    margin: 40px;
  }
}
@media (max-width: 940px) {
  .signUpImage img{
    object-fit: contain;
    max-width: 350px;
  }
  .signUpImage p{
    width: initial;
  }
}

@media (max-width: 767px) {
  .wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column-reverse;
  }
  .signInImage{
   /*display: none;*/
    height: 300px;
    padding: 0;
    margin-bottom: 30px;
  }
  .form{
    padding: 0 15px;
    width: 100%;
    min-width: 100%;
  }
  .signUpImage{
   display: none;
  }
  .signUpTitle{
    text-align: center!important;
    margin-top: 100px !important;
  }
  .signUpText{
    text-align: center;
  }
  .logo{
    top: 5px;
    left: 12px;
  }
}