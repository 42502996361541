.wrapper {
  align-items: center;
  column-gap: 24px;
  display: grid;
  grid-template-columns: 150px 1fr;
  margin: 20px 0px;
  row-gap: 24px;
}

.label {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
}

.saveSettingsTemplate {
  align-items: center;
  color: var(--app-text-secondary-color);
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin-top: 30px;
}

.saveSettingsTemplate label {
  margin-right: 10px;
}
