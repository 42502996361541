.titleWrapper,
.tools,
.reorderBtn,
.tableActions {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.reorderBtn {
  margin-right: 12px;
}

.reorderBtn svg path {
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.reorderBtn:hover svg path {
  fill: #4096ff;
}

.instanceNameColumn {
  color: #101828;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
}

.instanceDescriptionColumn {
  color: var(--app-text-secondary-color);
  font-size: 14px;
  font-weight: 400;
  overflow-y: auto;
}

.instanceDescriptionColumn::-webkit-scrollbar {
  display: none;
}

.tableActions {
  justify-content: flex-end;
  width: 100%;
}

.tableActions svg {
  cursor: pointer;
}

.tableActions svg:not(:first-of-type),
.tableActions a {
  margin-left: 10px;
}

.tableActions svg:hover path {
  stroke: var(--app-text-highlight-color);
}
