.wrapper {
  align-items: center;
  border-bottom: 2px solid #EAECF0;
  display: flex;
  height: var(--header-height);
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 0px 80px;
}

.wrapper svg {
  cursor: pointer;
}

.wrapper > div {
  align-items: center;
  display: flex;
  height: 100%;
}

.logo {
  height: 100%;
  padding: 10px 0px;
}

.menuItem {
  border-radius: 8px;
  color: var(--app-text-secondary-color);
  font-weight: 500;
  margin-left: 12px;
  padding: 8px 12px;
  text-decoration: none;
}

.menuItem:hover {
  color: var(--app-text-highlight-color);
}

.menuItem.active {
  background-color: #F9FAFB;
  color: var(--app-text-highlight-color);
}
