.wrapper h1 {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.wrapper h1 button {
  background-color: #E0EFFE;
  border: transparent;
  color: var(--app-text-highlight-color);
}

.wrapper h2 {
  font-size: 18px;
  font-weight: 500;
  margin: 16px 0px 6px 0px;
  text-align: left;
}

.wrapper h3 {
  color: var(--app-text-secondary-color);
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 21px;
  text-align: left;
}

.radioGroupOption {
  display: grid;
  grid-template-columns: 16px 1fr;
}

.radioGroupOption::after {
  height: 0px;
}

.radioGroupOption span {
  align-self: flex-start !important;
}

.radioGroupOption span:first-of-type {
  width: 16px;
}

.radioGroupOption span:last-of-type {
  width: 100%;
}

.radioGroupOption span .name {
  align-items: center;
  color: var(--app-text-secondary-color);
  display: flex;
  font-size: 14px;
  font-weight: 500;
  margin-top: -3px;
}

.radioGroupOption span .description {
  color: var(--app-text-secondary-color);
  font-size: 12px;
  margin-bottom: 15px;
}

.radioGroupOption  span .withSelectAll {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.selectAll {
  border-radius: 8px;
  color: var(--app-text-highlight-color);
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
}

.selectAll:hover {
  background-color: #E0EFFE;
}
