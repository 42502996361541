.link {
  align-items: center;
  color: var(--app-text-highlight-color);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.link svg {
  margin-right: 8px;
}

.link svg path {
  fill: var(--app-text-highlight-color);
}
