.wrapper h1 {
  margin-bottom: 10px;
  margin-top: 34px;
}

.actionButtons {
  margin-top: 24px;
}

.actionButtons button {
  width: 93px;
}

.warning {
  color: var(--app-text-secondary-color);
  font-size: 12px;
  margin-right: 15px;
}

