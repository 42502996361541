.noData {
  width: fit-content;
  margin: 72px auto 72px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title{
    color: var(--Gray-900, #101828);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 16px 0 4px;
  }

  &__text{
    color: var(--Gray-500, #667085);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    max-width: 298px;
  }
}

.wrapper {
  padding-bottom: 30px;

  .plan {
    background: rgba(241, 247, 253, 0.60);
    border-radius: 16px;
    margin-bottom: 32px;
    padding: 26px 20px;
  
    .planName {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 16px;
    }
  }

  h2 {
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  h3 {
    color: var(--app-text-secondary-color);
    font-size: 14px;
    width: fit-content;
  }

  .tokensWrapper {
    column-gap: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.inner {
  margin-top: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #EAECF0;
  display: flex;
  justify-content: space-between;
}

// .title {
//   color: var(--Gray-900, var(--Text-Primary, #101828));
//   font-family: Inter, sans-serif;
//   font-size: 18px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 28px;
//   margin-bottom: 4px;
//   margin-top: 0;
// }

.subtitle {
  color: var(--Gray-500, #667085);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.creditsInfo {
  display: flex;
  margin-top: 25px;
  gap: 24px;
}

.credits {
  color: var(--Gray-700, #344054);
  font-family: Inter, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin: 0 0 8px 0;
  span {
    margin-left: 4px;
    color: var(--Gray-500, #667085);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.dateBadge {
  margin: 0;
  border-radius: 16px;
  background: var(--Success-50, #ECFDF3);
  width: fit-content;
  color: var(--Success-700, #027A48);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 2px 10px;
  margin-bottom: 10px;
}

.patientNumber {
  color: var(--Gray-700, #344054);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  margin-bottom: 6px;
}
