.switchIcon {
  margin: 0 auto;
  margin-bottom: 10px;
  width: 100%;
}

.title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
  text-align: center;
}

.description {
  color: var(--app-text-secondary-color);
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
}

.userData {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 12px;
  position: relative;
}

.userName {
  color: #344054;
  font-weight: 600;
}

.userEmail {
  color: var(--app-text-secondary-color);
}

.selectBtn {
  color: var(--app-primary-color);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.selectBtn.disabled {
  color: var(--app-text-secondary-color);
  cursor: default;
}
